<template>
  <div>
    <template v-if="isVideo">
      <video
        :class="videoClasses"
        :style="videoStyles"
        :width="computedWidth"
        :height="computedHeight"
        autoplay
        muted
        loop
        :src="src"
        :id="id"
      ></video>
    </template>
    <template v-else>
      <b-img-lazy
        :class="imageClasses"
        :style="imageStyles"
        blank-src=""
        blankColor="#01010145"
        :width="computedWidth"
        :height="computedHeight"
        :src="src"
        alt="home-page-image"
        :id="id"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: "MultiMedia",
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      default: "100%",
    },
    height: {
      type: [String, Number],
      default: "100%",
    },
    id: {
      type: String,
      default: "",
    },
    classes: {
      type: [String, Object, Array],
      default: "",
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isVideo() {
      if (!this.src) return false;
      const extension = this.src.split(".").pop().toLowerCase();
      return ["mp4", "webm", "ogg"].includes(extension);
      // return this.src.type.startsWith('video/'); // Si tienes acceso al tipo MIME
    },
    computedWidth() {
      return typeof this.width === "number" ? `${this.width}px` : this.width;
    },
    computedHeight() {
      return typeof this.height === "number" ? `${this.height}px` : this.height;
    },
    videoClasses() {
      return [
        "video-class", // we can add default classes here
        this.classes,
      ];
    },
    imageClasses() {
      return [
        "image-class", // we can add default classes here
        this.classes,
      ];
    },
    videoStyles() {
      return {
        // we can add default styles here
        ...this.styles,
      };
    },
    imageStyles() {
      return {
        // we can add default styles here
        ...this.styles,
      };
    },
  },
};
</script>

<style scoped>
/* Añade estilos personalizados si es necesario */
</style>
